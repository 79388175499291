<template>
  <section class="relative" id="afxtoken">
    <div class="bg-radial"></div>
    <div
      class="max-w-6xl mx-auto px-4 lg:px-12 flex flex-col-reverse md:flex-row gap-8 py-8 lg:py-24"
    >
      <div
        class="img flex flex-1 justify-center md:justify-start lg:items-center svg mb-8 -mt-20 md:mt-0"
      >
        <img src="../assets/images/InfoBox/AfxToken.png" alt="AFX Token" />
      </div>

      <div class="text-md flex-1">
        <h1
          class="text-4xl text-left lg:text-5xl text-white mb-4 leading-9 font-bold leading-head"
        >
          <span class="text-primary">AFX</span> Token.
          <div class="block">Our own token.</div>
        </h1>
        <div class="text-white leading-relaxed">
          <p>Site currency for all platform payments.</p>
          <ul class="list-disc ml-7 mb-4">
            <li>Buy with Bitcoin/Lightning or XLM.</li>
          </ul>
          <p class="mb-4">
            The platform buys back unused AFX with a small spread with our own
            market maker.
          </p>
          <p>
            AFX is currently not a speculative asset, it is a payment token and
            a discount vehicle.
          </p>
          <ul class="list-disc ml-7">
            <li>
              The token will be used to power our AMM on stellar DEX and more.
            </li>
            <li>
              It will also be used in our loyalty program for cash back on
              platform usage.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// TODO: FIX:  get radial from figma when ready
export default {
  name: "AfxToken",
};
</script>

<style lang="scss" scoped>
.img {
  img {
    margin-right: -58%;
    max-width: 160%;
  }
}

@media screen and (min-width: 640px) {
  .img {
    img {
      margin-right: -30%;
      max-width: 100%;
      height: auto;
    }
  }
}

@media screen and (min-width: 768px) {
  .img {
    img {
      max-width: 100%;
    }
  }
}

.bg-radial {
  position: absolute;
  width: 542.49px;
  height: 372.97px;
  left: 50%;
  top: -10%;

  background: radial-gradient(
    50% 50% at 50% 50%,
    #00a34b 0%,
    rgba(0, 122, 118, 0.35) 100%
  );
  filter: blur(80px);
  transform: rotate(-86.1deg);
}

@media screen and (min-width: 640px) {
  .bg-radial {
    filter: blur(120px);
  }
}

@media screen and (min-width: 768px) {
  .bg-radial {
    width: 642.49px;
    height: 372.97px;
    top: 384.36px;
    left: -430px;
    filter: blur(75px);
  }
}

.leading-head {
  line-height: 3.6875rem !important;
}

/* MY STYLE ! DESIGNERS */
// .svg {
//   position: relative;

//   img {
//     width: 95%;
//   }

//   &:before {
//     content: "";
//     position: absolute;
//     width: 5rem;
//     height: 0.375rem;
//     left: 40%;
//     top: 110%;
//     background: url("../assets/images/InfoBox/AfxToken-bar.png");
//     background-repeat: no-repeat;
//     background-size: 100%;
//     transform: rotate(90deg);
//   }
// }

// @media screen and (min-width: 768px) {
//   .svg {
//     width: 87%;
//     &:before {
//       left: 98%;
//       top: 55%;
//       transform: rotate(0deg);
//     }
//   }
// }
</style>
