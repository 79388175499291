<template>
  <section id="hero">
    <swiper
      :slides-per-view="1"
      :space-between="50"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      :pagination="{ clickable: true }"
      :scrollbar="{ draggable: true }"
      class="bg-cover w-full border-none"
    >
      <swiper-slide class="slide-1 text-white">
        <div
          class="grid grid-cols-1 sm:grid-cols-12 max-w-6xl mx-auto px-4 sm:px-6 lg:px-14 lg:pt-6 h-swiper"
        >
          <span class="sm-device-1 sm:hidden"> </span>

          <div
            class="relative sm:col-span-6 flex justify-center items-start flex-col pt-3 lg:pt-10 sm-bg"
          >
            <span class="l-radial"></span>
            <span class="r-radial"></span>

            <h1
              class="text-5xl sm:text-4xl lg:text-6xl xl:text-6xl text-white font-bold mb-5 z-50"
            >
              <span class="block mb-2">Automated</span>
              <span class="text-ternary">Trading Bots</span>
            </h1>

            <div class="text z-20">
              <span class="tracking-wide"
                >Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab
                aliquid aut est eveniet exercitationem facere facilis nihil
                omnis praesentium quidem quo quod rem saepe similique sunt
                tempore ullam, vitae voluptas.</span
              >
            </div>

            <div class="text z-20 mt-4">
              <span class="tracking-wide"
                >Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Exercitationem, fugit!</span
              >
            </div>

            <div class="flex space-x-5 mt-7">
              <a
                href="#"
                class="items-center box-border rounded-md whitespace-nowrap border border-gray-50 p-4 lg:px-5 text-gray-100 font-normal lg:font-semibold btn-highlight lg:text-md z-50"
                >Apply for Beta Access</a
              >
            </div>
          </div>

          <div
            class="bg-device sm:col-span-6 hidden sm:flex justify-center items-center z-20"
          ></div>
        </div>
      </swiper-slide>

      <swiper-slide class="slide-2 text-white">
        <div
          class="grid grid-cols-1 sm:grid-cols-5 max-w-6xl mx-auto px-4 sm:px-6 lg:px-12 h-swiper"
        >
          <span class="sm-mocap sm:hidden"> </span>

          <div
            class="relative sm:col-span-2 flex justify-center items-start flex-col pt-3 lg:pt-6 sm-bg"
          >
            <span class="l-radial"></span>

            <h1
              class="text-5xl sm:text-4xl lg:text-6xl xl:text-6xl text-white font-bold mb-5 z-50"
            >
              <span class="block mb-2">Managed</span>
              <span class="text-ternary">Portfolios</span>
            </h1>

            <div class="text z-20">
              <ol
                class="list-decimal ml-5 leading-8 font-normal lg:font-semibold text-base lg:text-md"
              >
                <li>Track Portfolios or Manage your own</li>
                <li>Sell your Portfolios in the Market Place</li>
              </ol>
            </div>

            <div class="flex space-x-5 mt-7 lg:mt-10">
              <a
                href="#"
                class="inline-flex items-center box-border rounded-md whitespace-nowrap px-4 lg:px-5 text-white font-normal lg:font-semibold btn-highlight lg:text-md z-50"
                >Become Tester</a
              >
              <a
                href="#"
                class="inline-flex items-center box-border rounded-md whitespace-nowrap px-6 py-4 font-normal lg:font-semibold text-base lg:text-md text-primary btn-dark-highlight"
                >Apply Here</a
              >
            </div>
          </div>

          <div
            class="bg-mocap sm:col-span-3 hidden sm:flex justify-center items-center z-20"
          ></div>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";

SwiperCore.use([Pagination]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    // onSwiper(swiper) {
    //   // console.log(swiper);
    // },
    // onSlideChange() {
    //   // console.log("slide change");
    // },
  },
};
</script>

<style scoped lang="scss">
.h-swiper {
  min-height: 700px;
}

@media screen and (min-width: 768px) {
  .r-radial {
    position: absolute;
    width: 642.49px;
    height: 372.97px;
    left: 118%;
    top: 20%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #00a34b 0%,
      rgba(0, 122, 118, 0.1) 100%
    );
    filter: blur(50px);
    transform: rotate(-46.6deg);
    z-index: 1;
    opacity: 0.2;
  }

  .l-radial {
    position: absolute;
    top: -13%;
    left: -82%;
    width: 90%;
    height: 100%;
    border: 1px solid red;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #00a34b 0%,
      rgba(0, 122, 118, 0.1) 100%
    );
    background-size: 380px 642px;
    background-position: 50%;
    background-repeat: no-repeat;
    filter: blur(50px);
    opacity: 0.1;
    transform: rotate(44.91deg);
    z-index: 1;
  }
}

@media screen and (min-width: 992px) {
  .l-radial {
    left: -32%;
  }
}

.bg-device {
  background: url("../assets/images/heroSlider/device.png");
  background-repeat: no-repeat;
  background-size: 85%;
  // background-position: 2rem calc(50% + 1.875rem);
  background-position: center;
}

.bg-mocap {
  background: url("../assets/images/heroSlider/mocap.png");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: 3rem calc(50% + 1.875rem);
}

@media screen and (max-width: 640px) {
  .sm-device-1 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../assets/images/heroSlider/device.png");
    background-repeat: no-repeat;
    background-size: 160%;
    background-position: 4rem calc(25% + 1.875rem);
    opacity: 0.4;
  }

  .sm-mocap {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../assets/images/heroSlider/mocap.png");
    background-repeat: no-repeat;
    background-size: 160%;
    background-position: 4rem calc(25% + 1.875rem);
    opacity: 0.4;
  }
}

::v-deep .swiper-pagination-bullet {
  width: 3.625rem;
  height: 4px;
  display: inline-block;
  border-radius: 1px;
  background: #ffffff7a;
  opacity: 0.5;
}

::v-deep .swiper-pagination-bullet-active {
  opacity: 1;
  background: #00A34B;
}

.text-white-7 {
  color: rgba(255, 255, 255, 0.7);
}

.swiper-container {
  position: relative;
}

.swiper-pagination {
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translateX(-50%);
}
</style>
