<template>
  <section class="pricing py-4 relative" id="pricing">
    <div class="bg-radial z-0"></div>
    <div class="max-w4xl mx-auto px-4 lg:px-8 my-10 z-50">
      <div
        class="flex flex-col justify-center items-center text-center text-white"
      >
        <h1 class="text-3xl lg:text-5xl font-bold mb-3">Pricing</h1>
        <p class="w-2/4 sm:w-full mt-4 text-md mb-3 leading-normal text-gray-400">
          Fair pricing, pay for what you use.
        </p>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 mt-6 sm:mt-10">
        <div
          class="flex flex-col justify-center items-baseline p-12 bg-black rounded-tl-lg rounded-tr-lg sm:rounded-tr-none sm:rounded-tl-lg sm:rounded-bl-lg"
        >
          <div class="flex justify-center w-full mb-4 sm:justify-start">
            <h2
              class="bg-black-light px-4 py-2 rounded-full mb-4 text-primary text-sm sm:text-base"
            >
              3 MONTHS
            </h2>
          </div>

          <div class="text-5xl lg:text-6xl text-white font-extrabold">
            $49
            <span class="text-3xl font-semibold"> /3 m </span>
          </div>
          <p class="text-md text-gray-100 font-semibold mt-5">
            Lorem ipsum dolor sit amet <br />
            consectetur adipisicing elit.
          </p>
        </div>

        <div
          class="flex flex-col justify-center items-baseline px-4 py-8 lg:p-12 bg-black-light rounded-bl-lg rounded-br-lg sm:rounded-bl-none sm:rounded-tr-lg sm:rounded-br-lg space-y-5"
        >
          <div class="flex space-x-3">
            <CheckIcon class="h-5 w-5 text-primary" />
            <p class="text-white">Lorem ipsum is simply dummy text</p>
          </div>
          <div class="flex space-x-3">
            <CheckIcon class="h-5 w-5 text-primary" />
            <p class="text-white">Lorem ipsum is simply dummy text</p>
          </div>
          <div class="flex space-x-3">
            <CheckIcon class="h-5 w-5 text-primary" />
            <p class="text-white">Lorem ipsum is simply dummy text</p>
          </div>
          <div class="flex space-x-3">
            <CheckIcon class="h-5 w-5 text-primary" />
            <p class="text-white">Lorem ipsum is simply dummy text</p>
          </div>

          <div class="flex justify-center w-full mb-4 lg:justify-start">
            <button
              class="py-4 btn-highlight text-white rounded-md w-2/4 md:w-3/4 lg:w-11/12 ml-2"
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/outline";

export default {
  name: "Pricing",
  components: {
    CheckIcon,
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 640px) {
  .bg-radial {
    position: absolute;
    top: -6%;
    height: 75%;
    width: 31%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 163, 75, 0.5) 0%,
      rgba(0, 122, 118, 0.1) 100%
    );
    filter: blur(50px);
    left: -5rem;
  }
}

.max-w4xl {
  max-width: 59.625rem;
}

.bg-black-light {
  background-color: #333333;
}
</style>
