<template>
  <HeroSlider />
  <TradingStrategy />
  <AfxToken />
  <Strategy />
  <Pricing />
  <FaqHome />
</template>

<script>
// @ is an alias to /src
import HeroSlider from "@/components/HeroSlider.vue";
import TradingStrategy from "@/components/TradingStrategy.vue";
import AfxToken from "@/components/AfxToken.vue";
import Strategy from "@/components/Strategy.vue";
import Pricing from "@/components/Pricing.vue";
import FaqHome from "@/components/FaqHome.vue";

export default {
  name: "Home",
  components: {
    HeroSlider,
    TradingStrategy,
    AfxToken,
    Strategy,
    Pricing,
    FaqHome,
  },
};
</script>
