<template>
  <section
    class="max-w-6xl mx-auto px-4 md:px-6 lg:px-12 py-12 lg:py-20"
    id="faq"
  >
    <div
      class="flex justify-between items-center flex-col sm:flex-row space-y-4 mt-16 mb-12"
    >
      <h1
        class="text-4xl lg:text-5xl font-bold text-white text-center sm:text-left leading-normal"
      >
        Frequently Asked Questions
      </h1>

      <button
        class="whitespace-nowrap rounded-md px-8 py-4 text-md text-gray-100 btn-highlight"
      >
        See More
      </button>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-12">
      <div class="space-y-3" v-for="faq in faqs" :key="faq.id">
        <h2 class="text-primary text-md font-semibold">
          {{ faq.q }}
        </h2>
        <p class="leading-loose text-white">
          {{ faq.a }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FaqHome",
  data() {
    return {
      faqs: [
        {
          id: 1,
          q: "Lorem Ipsum is simply dummy text?",
          a:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        },
        {
          id: 2,
          q: "Lorem Ipsum is simply dummy text?",
          a:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        },
        {
          id: 3,
          q: "Lorem Ipsum is simply dummy text?",
          a:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        },
        {
          id: 4,
          q: "Lorem Ipsum is simply dummy text?",
          a:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        },
        {
          id: 5,
          q: "Lorem Ipsum is simply dummy text?",
          a:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        },
        {
          id: 6,
          q: "Lorem Ipsum is simply dummy text?",
          a:
            "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
