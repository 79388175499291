<template>
  <section class="relative">
    <div class="bg-radial-1"></div>
    <div class="bg-radial-2"></div>
    <div class="bg-radial-3"></div>

    <div
      class="max-w-6xl mx-auto px-4 md:px-6 lg:px-12 grid grid-cols-1 md:grid-cols-2 gap-8 py-8 lg:py-24"
    >
      <div class="flex justify-center items-start flex-col">
        <h1
          class="w-full col-span-1 md:col-span-2 text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-white font-bold flex justify-center items-center md:items-start xl:items-start flex-col leading-normal lg:leading-1-25 mb-5"
        >
          <span class="text-primary">Strategy Templates</span>
          <span class="">for both NodeJs</span>
          <span class="">and Python</span>
        </h1>
        <p class="text-white leading-loose">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
        </p>
      </div>
      <div class="relative h-full flex justify-center items-center">
        <img
          src="../assets/images/InfoBox/2Tabs.png"
          class="sm:w-10/12 md:w-full"
          alt="Window Tab"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "StrategyTemplate",
};
</script>

<style scoped>
.bg-radial-1 {
  position: absolute;
  top: 10%;
  left: 65%;
  width: 40.125rem;
  height: 23.75rem;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #00a34b 0%,
    rgba(0, 122, 118, 0.5) 100%
  );
  filter: blur(120px);
  transform: rotate(54.54deg);
}

.bg-radial-2 {
  position: absolute;
  top: 7%;
  left: 53%;
  width: 40.125rem;
  height: 23.75rem;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 163, 75, 0.5) 0%,
    rgba(0, 122, 118, 0.05) 100%
  );
  filter: blur(85px);
  transform: rotate(54.54deg);
}

.bg-radial-3 {
  position: absolute;
  top: 54%;
  left: 77%;
  width: 20.125rem;
  height: 20.75rem;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 163, 75, 0.5) 0%,
    rgba(0, 122, 118, 0) 100%
  );
  filter: blur(75px);
  transform: rotate(54.54deg);
  z-index: 100;
}

@media (min-width: 992px) {
  .lg\:leading-1-25 {
    line-height: 1.25;
  }
}

.leading-head {
  line-height: 3.6875rem !important;
}
</style>
